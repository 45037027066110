import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ActorWithPermissionsResponse, IdName, MyHttpApi, OtherActorUpdateRequest } from 'utils/api';
import { validEmail } from 'utils/email-utils';
import { Notify } from 'utils/notify';

@autoinject
export class ActorsNormalEdit {
  private actor: OtherActorUpdateRequest = {
    canBookkeepingIntegration: false,
    canEAgreement: false,
    canGiftCard: false,
    canRemoteControl: false,
    canCreateUsers: false,
    canManifestIntegration: false,
    canIntegrationSettings: false,
    canPosItemAdmin: false,
    firstName: "",
    lastName: "",
    username: "",
    clientId: [], businessGroupId: [], corporationId: [],
    delete: false,
  };
  private actorClient: IdName[] = [];
  private actorBusinessGroup: IdName[] = [];
  private actorCorporation: IdName[] = [];
  private targetActor?: ActorWithPermissionsResponse;
  private self?: ActorWithPermissionsResponse;
  private clientList: IdName[] = [];
  private businessGroupList: IdName[] = [];
  private corporationList: IdName[] = [];
  private readOnlyClients: IdName[] = [];
  private readOnlyGroups: IdName[] = [];
  private readOnlyCorporations: IdName[] = [];

  constructor(private readonly notify: Notify, private readonly router: Router, private readonly api: MyHttpApi, private readonly i18n: I18N) {
  }

  async activate(params: { id?: string; }) {
    this.self = await this.api.actorSelf();
    this.clientList = this.self.fullPermissions;
    this.businessGroupList = this.self.groups;
    this.corporationList = this.self.corporations;

// ! Most likely we need to alter how we get groups in self...
    if (params.id) {
      this.targetActor = await this.api.actorOtherById({ id: parseInt(params.id) });
      this.actorClient = this.targetActor.units.filter(a => this.clientList.find(c => c.id === a.id));
      this.actorBusinessGroup = this.targetActor.groups.filter(g => this.businessGroupList.find(bg => bg.id === g.id));
      this.actorCorporation = this.targetActor.corporations.filter(c => this.corporationList.find(co => co.id === c.id));
      this.actor = {
        ...this.targetActor.actor,
        delete: !!this.targetActor.actor.deleteTime,
        clientId: [], businessGroupId: [], corporationId: [],
      };
      this.readOnlyClients = this.targetActor.units.filter(a => !this.clientList.find(c => c.id === a.id));
      this.readOnlyGroups = this.targetActor.groups.filter(g => !this.businessGroupList.find(bg => bg.id === g.id));
      this.readOnlyCorporations = this.targetActor.corporations.filter(c => !this.corporationList.find(co => co.id === c.id));
    }
  }

  validateUsername(username?: string) {
    if (!username || !validEmail(username)) {
      return this.i18n.tr("actor.usernameNotEmail");
    }
    return "";
  }

  async save(deleted: boolean) {
    let usernameError = this.validateUsername(this.actor.username);
    if (!this.actor.delete && usernameError) {
      this.notify.info(usernameError);
      return;
    }
    let tmp: OtherActorUpdateRequest = {
      ...this.actor,
      delete: deleted,
      clientId: this.actorClient.map(x => x.id),
      businessGroupId: this.actorBusinessGroup.map(x => x.id),
      corporationId: this.actorCorporation.map(x => x.id),
    };
    if (
      tmp.clientId.length == 0 && tmp.businessGroupId.length == 0 && tmp.corporationId.length == 0 &&
      this.readOnlyClients.length == 0 && this.readOnlyGroups.length == 0 && this.readOnlyCorporations.length == 0
    ) {
      this.notify.info("actor.permissionsOrDelete");
      return;
    }
    await this.api.actorCreateUsersUpdate(tmp);
    this.router.navigateToRoute("actors/normal/list");
  }

  async delete() {
    if (!this.actor) {
      return;
    }
    await this.save(true);
  }

  @computedFrom("clientList", "actorClient")
  get dynamicClientList() {
    return this.clientList.filter(x => !(this.actorClient.some(ac => ac.id === x.id)));
  }

  @computedFrom("actorBusinessGroup", "businessGroupList")
  get dynamicBusinessGroupList() {
    return this.businessGroupList.filter(x => !(this.actorBusinessGroup.some(abg => abg.id === x.id)));
  }

  @computedFrom("actorCorporation", "corporationList")
  get dynamicCorporationList() {
    return this.corporationList.filter(x => !(this.actorCorporation.some(ac => ac.id === x.id)));
  }

  @computedFrom("readOnlyClients")
  get readOnlyClientNames() {
    return this.readOnlyClients.map(a => a.name).join(', ');
  }

  @computedFrom("readOnlyGroups")
  get readOnlyGroupNames() {
    return this.readOnlyGroups.map(g => g.name).join(', ');
  }

  @computedFrom("readOnlyCorporations")
  get readOnlyCorporationNames() {
    return this.readOnlyCorporations.map(co => co.name).join(', ');
  }
}
